import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { ClipboardIcon, CheckIcon, CodeIcon } from "@heroicons/react/outline";
import { isVSCode } from "../utils/environment";

const CopyMessageButton = React.memo(({ onCopy, copied }) => (
  <button
    onClick={onCopy}
    className={`text-xs bg-slate-700/50 rounded-full px-3 py-1.5 transition duration-200 flex items-center ${
      copied
        ? "text-blue-400"
        : "text-gray-300 hover:text-white hover:bg-slate-600/50"
    }`}
    title="Copy Message"
  >
    {copied ? (
      <>
        <CheckIcon className="h-3 w-3 mr-1" />
        Copied
      </>
    ) : (
      <>
        <ClipboardIcon className="h-3 w-3 mr-1" />
        Copy
      </>
    )}
  </button>
));

const ChatMessage = ({
  msg,
  user,
  isLoading,
  highlightWhenComplete = false,
}) => {
  const messageRef = useRef(null);
  const [copiedCodeIds, setCopiedCodeIds] = useState({});
  const [insertedCodeIds, setInsertedCodeIds] = useState({});
  const [copiedMessage, setCopiedMessage] = useState(false);

  const [shouldHighlight, setShouldHighlight] = useState(highlightWhenComplete);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).catch((error) => {
      console.error("Failed to copy text: ", error);
    });
  }, []);

  const insertCode = useCallback((code) => {
    const vscode = window.acquireVsCodeApi();
    vscode.postMessage({ command: "insert", text: code });
  }, []);

  const handleCopyCodeClick = useCallback(
    (code, codeId) => {
      copyToClipboard(code);
      setCopiedCodeIds((prev) => ({ ...prev, [codeId]: true }));
      setTimeout(() => {
        setCopiedCodeIds((prev) => ({ ...prev, [codeId]: false }));
      }, 2000);
    },
    [copyToClipboard]
  );

  const handleInsertClick = useCallback(
    (code, codeId) => {
      insertCode(code);
      setInsertedCodeIds((prev) => ({ ...prev, [codeId]: true }));
      setTimeout(() => {
        setInsertedCodeIds((prev) => ({ ...prev, [codeId]: false }));
      }, 2000);
    },
    [insertCode]
  );

  const handleCopyMessageClick = useCallback(() => {
    copyToClipboard(msg.text);
    setCopiedMessage(true);
    setTimeout(() => setCopiedMessage(false), 2000);
  }, [copyToClipboard, msg.text]);

  const customRenderer = useMemo(() => {
    const renderer = new marked.Renderer();
    const langCounters = {};
    renderer.code = (code, language) => {
      const lang = code.lang || "plaintext";
      langCounters[lang] = (langCounters[lang] || 0) + 1;
      const codeId = `message-${msg.id}-code-${lang}-${langCounters[lang]}`;
      const highlightedCode = hljs.highlight(lang, code.text).value;

      return `
        <div class="relative my-6 rounded-lg overflow-hidden shadow-xl border border-slate-700" data-code-id="${codeId}">
          <div class="flex justify-between items-center bg-gradient-to-r from-slate-800 to-slate-700 text-white p-3">
            <div class="flex items-center">
              <svg class="h-5 w-5 mr-2 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
              </svg>
              <span class="text-sm font-mono font-semibold">${lang}</span>
            </div>
            <div class="flex space-x-2">
              <button
                data-action="copy"
                data-code-id="${codeId}"
                class="copy-button text-xs bg-slate-600 text-white rounded-md px-3 py-1.5 hover:bg-slate-500 transition-colors duration-200 flex items-center focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                title="Copy Code"
              >
                <svg class="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                <span>${copiedCodeIds[codeId] ? "Copied!" : "Copy Code"}</span>
              </button>
              ${
                isVSCode()
                  ? `
                <button
                  data-action="insert"
                  data-code-id="${codeId}"
                  class="insert-button text-xs bg-blue-600 text-white rounded-md px-3 py-1.5 hover:bg-blue-500 transition-colors duration-200 flex items-center focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                  title="Insert Code"
                >
                  <svg class="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  <span>${
                    insertedCodeIds[codeId] ? "Inserted!" : "Insert Code"
                  }</span>
                </button>
              `
                  : ""
              }
            </div>
          </div>
          <div class="bg-slate-900 p-4">
            <pre class="m-0"><code class="hljs ${lang} block overflow-x-auto text-sm">${highlightedCode}</code></pre>
          </div>
        </div>
      `;
    };

    renderer.heading = (text, level) => {
      const sizes = {
        1: "text-2xl",
        2: "text-xl",
        3: "text-lg",
        4: "text-base",
        5: "text-sm",
        6: "text-xs",
      };
      return `<h${level} class="${sizes[level]} font-bold mt-6 mb-3 text-blue-300">${text.text}</h${level}>`;
    };

    renderer.paragraph = (text) => {
      return `<p class="mt-3 mb-3 leading-relaxed">${text.text}</p>`;
    };

    renderer.list = (body, ordered) => {
      const tag = ordered ? "ol" : "ul";
      const listItems = body.items
        .map(
          (item) => `
          <li class="mb-2 pl-2">
            ${marked.parser(item.tokens)}
          </li>
        `
        )
        .join("");
      return `
        <${tag} class="${
          ordered ? "list-decimal" : "list-disc"
        } mt-3 mb-3 ml-6 space-y-1">
          ${listItems}
        </${tag}>
      `;
    };

    renderer.blockquote = (quote) => {
      return `<blockquote class="border-l-4 border-blue-500 pl-4 py-2 my-4 bg-slate-800/50 italic">${quote}</blockquote>`;
    };

    return renderer;
  }, [msg.id, copiedCodeIds, insertedCodeIds]);

  const renderedHtml = useMemo(() => {
    return marked(msg.text, { renderer: customRenderer });
  }, [msg.text, customRenderer]);

  useEffect(() => {
    if (shouldHighlight && messageRef.current) {
      messageRef.current.querySelectorAll("pre code").forEach((block) => {
        hljs.highlightElement(block);
      });
    }
  }, [renderedHtml, shouldHighlight]);

  useEffect(() => {
    const handleButtonClick = (event) => {
      const button = event.target.closest("button");
      if (!button) return;

      const action = button.getAttribute("data-action");
      const codeId = button.getAttribute("data-code-id");
      if (action && codeId) {
        const codeBlock = messageRef.current.querySelector(
          `[data-code-id="${codeId}"] pre code`
        );
        if (codeBlock) {
          const code = codeBlock.textContent;
          if (action === "copy") {
            handleCopyCodeClick(code, codeId);
          } else if (action === "insert") {
            handleInsertClick(code, codeId);
          }
        }
      }
    };

    const refCurrent = messageRef.current;
    if (refCurrent) {
      refCurrent.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("click", handleButtonClick);
      }
    };
  }, [handleCopyCodeClick, handleInsertClick]);

  return (
    <div ref={messageRef} className="flex flex-col items-start mb-3 sm:mb-4">
      <div className="flex items-center mb-2">
        {msg.type === "user" || msg.type === "user-code" ? (
          <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-400 to-indigo-500 flex items-center justify-center">
            <span className="text-white text-sm font-semibold">
              {user.first_name.charAt(0)}
            </span>
          </div>
        ) : (
          <div className="w-8 h-8 rounded-full bg-gradient-to-r from-purple-400 to-pink-500 flex items-center justify-center">
            <span className="text-white text-sm font-semibold font-space">
              AI
            </span>
          </div>
        )}
        <span className="ml-2 text-sm text-gray-300">
          {msg.type === "user" || msg.type === "user-code" ? (
            "You"
          ) : (
            <span className="font-spac3">CodeAI</span>
          )}
        </span>
      </div>
      <div
        className={`relative flex flex-col space-y-1 sm:space-y-2 p-3 sm:p-4 text-xs sm:text-sm rounded-lg shadow-md backdrop-blur-md w-full ${
          msg.type === "code" || msg.type === "user-code"
            ? "bg-slate-800/90 text-white"
            : "bg-slate-700/30 text-gray-200"
        }`}
      >
        <div
          className="prose prose-sm prose-invert max-w-none"
          dangerouslySetInnerHTML={{ __html: renderedHtml }}
        />
        <div className="flex justify-end mt-2">
          <CopyMessageButton
            onCopy={handleCopyMessageClick}
            copied={copiedMessage}
          />
        </div>
      </div>
      {isLoading && (
        <div className="flex justify-center mt-2">
          <div className="flex justify-center space-x-1">
            <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce"></div>
            <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce delay-75"></div>
            <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce delay-150"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ChatMessage);
