import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Chat from "./components/Chat";
import Settings from "./components/Settings";
import Sidebar from "./components/Sidebar";
import { AuthProvider } from "./utils/authProvider";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Add the Google Analytics script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-VKXN5VX34Z";
    script.async = true;
    document.head.appendChild(script);

    // Add the inline script to initialize Google Analytics
    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VKXN5VX34Z');
      gtag('event', 'platform', {
        'platform': navigator.platform // Capture the platform information
      });
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return (
    <AuthProvider>
      <Router>
      <div className="flex h-screen overflow-hidden bg-slate-900">
          <div className={sidebarOpen ? "w-40" : "w-0"} style={{ transition: 'width 300ms ease-in-out' }}>
            <Sidebar isOpen={sidebarOpen} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
          </div>
          <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
            <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
            <main className="flex-1 overflow-hidden">
              <Routes>
                <Route path="/" element={<Chat />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </main>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;