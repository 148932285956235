import axios from "axios";
import { logout } from "./authProvider"; // Import the logout function from the AuthProvider

const api = axios.create({
  baseURL: "https://api.codeai.studio/api",
});

// Add a response interceptor to handle 401 errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const getUser = async (token) => {
  try {
    const response = await api.get("/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Ensure error is propagated
  }
};

export const getSettings = async (token) => {
  try {
    const response = await api.get("/key/settings", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching settings data:", error);
    throw error; // Ensure error is propagated
  }
};

export const setModel = async (token, model) => {
  try {
    const response = await api.post(
      "/key/token/set/model",
      { model },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error setting model:", error);
    throw error; // Ensure error is propagated
  }
};
