import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const ThemeContext = createContext();

// Define light and dark themes
const themes = {
  light: {
    background: 'bg-gray-100',
    text: 'text-gray-900',
    primary: 'bg-white',
    secondary: 'bg-gray-200',
    accent: 'bg-blue-500 text-white',
    header: 'bg-slate-900',
  },
  dark: {
    background: 'bg-gray-900',
    text: 'text-gray-100',
    primary: 'bg-gray-800',
    secondary: 'bg-gray-700',
    accent: 'bg-blue-600 text-white',
    header: 'bg-slate-700',
  },
};

// Create a provider component
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  // Load theme from local storage or default to 'light'
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
  }, []);

  // Save theme to local storage
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme: themes[theme], changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
