import React, { useRef, useState, useCallback, useEffect } from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { useSpring, animated, useTransition } from "react-spring";
import EmptyState from "./EmptyState";
import { useAuth } from "../utils/authProvider";
import { getSettings } from "../utils/api";
import { ChevronDoubleDownIcon } from "@heroicons/react/outline";
import { SendHorizontal, StopCircle, X } from "lucide-react";

import { PromptCards } from "./PromptCard";
import ChatMessage from "./ChatMessage";
import { models } from "../utils/models";
import { motion, AnimatePresence } from "framer-motion";

const CustomAlert = ({ children, onClose }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-2 px-4 flex justify-between items-center shadow-sm text-sm"
  >
    <div className="flex-1 flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-yellow-300"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>
        <span>{children}</span>
      </div>
      <button
        onClick={onClose}
        className="text-white hover:text-indigo-200 transition-colors ml-4"
      >
        <X size={16} />
      </button>
    </div>
  </motion.div>
);

export default function ChatComponent() {
  const [messageInput, setMessageInput] = useState("");
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showAlert, setShowAlert] = useState(true);

  const {
    token,
    user,
    setSettings,
    settings,
    currentModel,
    chatHistory,
    addMessageToHistory,
    requestsRemaining,
    updateSettings,
    decrementRequestsRemaining,
  } = useAuth();
  const endOfMessagesRef = useRef(null);
  const socketRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Shift") {
      setIsShiftPressed(true);
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!streaming) {
        sendMessage();
      }
    }
  };
  const handleKeyUp = (e) => {
    if (e.key === "Shift") {
      setIsShiftPressed(false);
    }
  };

  const isFreeUser = settings?.plan.toLowerCase() === "free" || !settings?.plan;

  // Improved animations
  const messageTransitions = useTransition(chatHistory, {
    keys: (message) => message.id,
    from: { opacity: 0, transform: "translateY(20px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-20px)" },
    config: { tension: 300, friction: 20 },
  });

  // Scroll to bottom button animation
  const scrollButtonSpring = useSpring({
    opacity: isAtBottom ? 0 : 1,
    transform: isAtBottom
      ? "scale(0.8) translateY(20px)"
      : "scale(1) translateY(0px)",
    config: { tension: 300, friction: 20 },
  });

  const handleChatgptResChunk = useCallback(
    (data, socket) => {
      if (!data || !data.content) return;

      const { streamId, content } = data;
      if (content.trim() === "[DONE]") {
        setLoadingMessageId(null);
        setStreaming(false);
        socket.disconnect();
        scrollToBottom();
        return;
      }

      const existingIndex = chatHistory.findIndex((msg) => msg.id === streamId);

      if (existingIndex !== -1) {
        const updatedMessage = {
          ...chatHistory[existingIndex],
          text: chatHistory[existingIndex].text + content,
          type: "response",
        };
        addMessageToHistory(updatedMessage);
      } else {
        const newMessage = {
          id: streamId,
          text: content,
          type: "response",
        };
        addMessageToHistory(newMessage);
      }

      if (isAtBottom) {
        scrollToBottom();
      }
    },
    [chatHistory, addMessageToHistory, isAtBottom]
  );
  const handleWebviewMessage = useCallback((event) => {
    const { type, text, messageId } = event.data;
    console.log(event.data);
    if (type === "user-code") {
      const formattedText = `\`\`\`\n${text}\n\`\`\``;
      setMessageInput((prevInput) => prevInput + "\n\n" + formattedText);
    }
  }, []);

  const sendMessage = useCallback(
    async (message = messageInput) => {
      if (
        !message ||
        typeof message !== "string" ||
        !message.trim() ||
        requestsRemaining <= 0
      )
        return;

      const messageId = uuidv4().toString();

      const newMessage = {
        id: messageId,
        text: message.trim(),
        type: "user",
      };
      addMessageToHistory(newMessage);

      const formattedChatHistory = [...chatHistory, newMessage].map((msg) => ({
        role:
          msg.type === "user" || msg.type === "user-code"
            ? "user"
            : "assistant",
        content: msg.text,
      }));

      const socket = io("https://api.codeai.studio", {
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        secure: true,
        transports: ["websocket"],
      });

      socketRef.current = socket;

      socket.on("connect", () => {
        socket.emit("startCodeAIChat", {
          chatHistory: formattedChatHistory,
          chat: message,
          streamId: messageId,
          userId: user,
        });

        setLoadingMessageId(messageId);
        setStreaming(true);
        setMessageInput("");
        decrementRequestsRemaining();
      });

      socket.on("chatgptResChunk", (data) =>
        handleChatgptResChunk(data, socket)
      );

      socket.on("resError", (data) => {
        console.error("Error:", data.error);
        alert("An error occurred");
        socket.disconnect();
        setStreaming(false);
      });

      window.addEventListener("message", handleWebviewMessage);

      scrollToBottom();
    },
    [
      messageInput,
      user,
      chatHistory,
      addMessageToHistory,
      handleChatgptResChunk,
      handleWebviewMessage,
      requestsRemaining,
      decrementRequestsRemaining,
    ]
  );
  const stopStreaming = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      setStreaming(false);
      setLoadingMessageId(null);
    }
  };
  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAtBottom(atBottom);
    }
  };

  const scrollToBottom = () => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    updateSettings();
  }, [updateSettings]);

  useEffect(() => {
    if (chatHistory.length > 0 && isAtBottom) {
      scrollToBottom();
    }
  }, [chatHistory, isAtBottom]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="h-full flex flex-col bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 text-white">
      {!user && chatHistory.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="flex-1 overflow-hidden flex flex-col">
          <div
            ref={chatContainerRef}
            className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
            onScroll={handleScroll}
          >
            <div className="max-w-4xl mx-auto p-4">
              {chatHistory.length === 0 ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="flex items-center justify-center h-full"
                >
                  <PromptCards onCardClick={sendMessage} />
                </motion.div>
              ) : (
                messageTransitions((style, item) => (
                  <motion.div style={style} key={item.id} className="mb-4">
                    <ChatMessage
                      msg={item}
                      user={user}
                      isLoading={loadingMessageId === item.id}
                    />
                  </motion.div>
                ))
              )}
              <div ref={endOfMessagesRef} />
            </div>
          </div>

          <motion.div
            style={scrollButtonSpring}
            className="fixed bottom-32 right-4 z-20 sm:bottom-34 sm:right-6"
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={scrollToBottom}
              className="bg-gradient-to-r from-blue-500 to-indigo-600 p-2 rounded-full shadow-lg text-white hover:from-blue-600 hover:to-indigo-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
              title="Scroll to Bottom"
            >
              <ChevronDoubleDownIcon className="h-6 w-6" />
            </motion.button>
          </motion.div>
          <AnimatePresence>
            {showAlert && isFreeUser && (
              <CustomAlert onClose={() => setShowAlert(false)}>
                Subscribe to Pro for 5x more usage with our latest models.
                <motion.button
                  href="https://www.codeai.studio/dashboard/upgrade"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="ml-4 bg-white text-indigo-600 px-3 py-1 rounded-md text-xs font-medium hover:bg-indigo-100 transition-colors"
                >
                  Upgrade
                </motion.button>
              </CustomAlert>
            )}
          </AnimatePresence>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="bg-gradient-to-r from-slate-900 via-slate-800 to-slate-900 z-10 shadow-lg border-t border-slate-700/50"
          >
            {requestsRemaining > 0 ? (
              <form
                className="relative w-full max-w-4xl mx-auto p-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!streaming) {
                    sendMessage();
                  }
                }}
              >
                <div className="relative">
                  <textarea
                    id="chat-input"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="w-full resize-none bg-slate-700/50 p-3 pr-12 text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg transition duration-200"
                    placeholder="Type your message here..."
                    rows="2"
                    required
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (streaming) {
                        stopStreaming();
                      } else {
                        sendMessage();
                      }
                    }}
                    className={`
        absolute right-2 top-2
        flex items-center justify-center
        rounded-full
        w-8 h-8
        text-sm font-medium text-white
        shadow-md
        transition-all duration-200
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-700
        ${
          streaming
            ? "bg-red-500 hover:bg-red-600 focus:ring-red-400"
            : "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 focus:ring-blue-400"
        }
      `}
                    title={streaming ? "Stop" : "Send"}
                  >
                    {streaming ? (
                      <StopCircle size={16} />
                    ) : (
                      <SendHorizontal size={16} />
                    )}
                  </motion.button>
                </div>
                <div className="flex justify-between items-center mt-2 text-xs text-gray-400">
                  <div className="flex items-center">
                    <img
                      src={
                        models.find(
                          (m) =>
                            m.name.toLowerCase() === currentModel.toLowerCase()
                        )?.logo
                      }
                      alt="Model Logo"
                      className="h-5 w-5 bg-white rounded-full p-0.5 mr-2"
                    />
                    <span>{currentModel.toUpperCase()}</span>
                  </div>
                  <span>
                    {isShiftPressed
                      ? "Release shift to send"
                      : "Use shift + return for new line"}
                  </span>
                </div>
              </form>
            ) : (
              <div className="text-center text-white p-4">
                <p className="mb-4">
                  You have used all your requests for this month. Please upgrade
                  your plan to get more requests.
                </p>
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href="https://www.codeai.studio/dashboard/upgrade"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-gradient-to-r from-blue-500 to-indigo-600 text-white px-6 py-3 rounded-lg hover:from-blue-600 hover:to-indigo-700 transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                >
                  Upgrade Plan
                </motion.a>
              </div>
            )}
          </motion.div>
        </div>
      )}
    </div>
  );
}
