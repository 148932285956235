import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { getSettings, getUser, setModel } from "./api";
import { debounce } from "lodash";

// Constants
const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  USER: "user",
  SETTINGS: "settings",
  CHAT_HISTORY: "chatHistory",
  CURRENT_MODEL: "currentModel",
};

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

let logout = null;

export const AuthProvider = ({ children }) => {
  const [token, setTokenState] = useState(() =>
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
  );
  const [user, setUserState] = useState(() =>
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER))
  );
  const [settings, setUserSettingsState] = useState(() =>
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS))
  );
  const [isConnected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sessionId = useRef(uuidv4());
  const [currentModel, setCurrentModelState] = useState(() =>
    localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL)
  );
  const [hasSubscription, setHasSubscription] = useState(true);
  const [chatHistory, setChatHistory] = useState(
    () =>
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_HISTORY)) || []
  );
  const socket = useRef(null);
  const [requestsRemaining, setRequestsRemaining] = useState(() => {
    const storedSettings = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)
    );
    return storedSettings ? storedSettings.requestsRemaining : 0;
  });

  const setToken = useCallback((token) => {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
      if (window.acquireVsCodeApi) {
        const vscode = window.acquireVsCodeApi();
        vscode.postMessage({ command: "saveToken", token: token });
      }
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    }
    setTokenState(token);
  }, []);

  const setUser = useCallback((user) => {
    if (user) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
    }
    setUserState(user);
  }, []);

  const setSettings = useCallback((settings) => {
    if (settings) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SETTINGS,
        JSON.stringify(settings)
      );
      setRequestsRemaining(settings.requestsRemaining);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SETTINGS);
      setRequestsRemaining(0);
    }
    setUserSettingsState(settings);
  }, []);

  const setCurrentModel = useCallback((model) => {
    if (model) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL, model);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_MODEL);
    }
    setCurrentModelState(model);
  }, []);

  const saveChatHistory = useCallback(
    debounce((messages) => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.CHAT_HISTORY,
        JSON.stringify(messages)
      );
    }, 1000),
    []
  );

  const addMessageToHistory = useCallback(
    (message) => {
      setChatHistory((prevMessages) => {
        const existingMessageIndex = prevMessages.findIndex(
          (msg) => msg.id === message.id
        );

        if (existingMessageIndex !== -1) {
          const updatedMessages = [...prevMessages];
          updatedMessages[existingMessageIndex] = {
            ...updatedMessages[existingMessageIndex],
            text: updatedMessages[existingMessageIndex].text + message.text,
          };
          saveChatHistory(updatedMessages);
          return updatedMessages;
        } else {
          const updatedMessages = [...prevMessages, message];
          saveChatHistory(updatedMessages);
          return updatedMessages;
        }
      });
    },
    [saveChatHistory]
  );

  logout = useCallback(() => {
    localStorage.clear();
    setToken(null);
    setUser(null);
    setSettings(null);
    setCurrentModel("");
    setHasSubscription(true);
    setChatHistory([]);
    setRequestsRemaining(0);
    sessionId.current = uuidv4();
    if (socket.current) {
      socket.current.emit("deregisterSession", {
        sessionId: sessionId.current,
      });
      socket.current.disconnect();
    }
  }, [setToken, setUser, setSettings, setCurrentModel]);

  const authenticate = useCallback(
    async (token) => {
      setIsLoading(true);
      try {
        if (socket.current && socket.current.connected) {
          socket.current.emit("authenticate", {
            token,
            sessionId: sessionId.current,
          });
        }
        const userData = await getUser(token);
        const settingsData = await getSettings(token);
        setSettings(settingsData);
        setUser(userData.user);
        setCurrentModel(settingsData.selectedModel);
        setRequestsRemaining(settingsData.requestsRemaining);
      } catch (error) {
        console.error("Authentication failed:", error);
        logout();
      } finally {
        setIsLoading(false);
      }
    },
    [sessionId, setSettings, setUser, setCurrentModel, logout]
  );

  const updateSettings = useCallback(async () => {
    try {
      const settingsData = await getSettings(token);
      setSettings(settingsData);
      setRequestsRemaining(settingsData.requestsRemaining);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  }, [token, setSettings]);

  const decrementRequestsRemaining = useCallback(() => {
    setRequestsRemaining((prev) => {
      const newValue = Math.max(0, prev - 1);
      const updatedSettings = { ...settings, requestsRemaining: newValue };
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SETTINGS,
        JSON.stringify(updatedSettings)
      );
      return newValue;
    });
  }, [settings]);

  useEffect(() => {
    const newSocket = io("https://api.codeai.studio", {
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      secure: true,
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      setConnected(true);
      newSocket.emit("registerSession", { sessionId: sessionId.current });
      socket.current = newSocket;
    });

    newSocket.on("disconnect", () => {
      setConnected(false);
      newSocket.disconnect();
    });

    newSocket.on("auth-success", (data) => {
      setToken(data.token);
      authenticate(data.token);
      newSocket.disconnect();
    });

    newSocket.on("auth-failure", (data) => {
      console.error("Auth failure:", data.message);
      logout();
      newSocket.disconnect();
    });

    return () => {
      if (newSocket.connected) {
        newSocket.emit("deregisterSession", { sessionId: sessionId.current });
        newSocket.disconnect();
      }
    };
  }, [sessionId, setToken, authenticate, logout]);

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      setToken(token);
      authenticate(token);
    }
  }, [setToken, authenticate]);

  const switchModel = useCallback(
    async (model) => {
      setCurrentModel(model);
      await setModel(token, model);
    },
    [token, setCurrentModel]
  );

  const contextValue = useMemo(
    () => ({
      token,
      user,
      authenticate,
      isConnected,
      isLoading,
      sessionId: sessionId.current,
      chatHistory,
      setChatHistory: (newHistory) => {
        setChatHistory(newHistory);
        saveChatHistory(newHistory);
      },
      addMessageToHistory,
      clearHistory: () => {
        setChatHistory([]);
        saveChatHistory([]);
      },
      setToken,
      setUser,
      setSettings,
      logout,
      settings,
      switchModel,
      currentModel,
      hasSubscription,
      requestsRemaining,
      updateSettings,
      decrementRequestsRemaining,
    }),
    [
      token,
      user,
      isConnected,
      isLoading,
      chatHistory,
      settings,
      currentModel,
      hasSubscription,
      requestsRemaining,
      authenticate,
      addMessageToHistory,
      setToken,
      setUser,
      setSettings,
      logout,
      switchModel,
      updateSettings,
      decrementRequestsRemaining,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { logout };
export default AuthProvider;
