import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { ChatAltIcon, CogIcon, XIcon } from "@heroicons/react/outline";

const navigation = [
  { name: "Chat", href: "/", icon: ChatAltIcon },
  { name: "Settings", href: "/settings", icon: CogIcon },
];

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  const sidebarVariants = {
    open: { x: 0, transition: { type: "spring", stiffness: 300, damping: 30 } },
    closed: { x: "-100%", transition: { type: "spring", stiffness: 300, damping: 30 } },
  };

  const itemVariants = {
    open: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1, duration: 0.3 },
    }),
    closed: { opacity: 0, y: 20 },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-[60]"
            onClick={toggleSidebar}
          />
          <motion.div
            ref={sidebarRef}
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            className="fixed inset-y-0 left-0 w-64 z-[70] bg-gradient-to-b from-slate-900 via-slate-800 to-slate-900 shadow-xl border-r border-slate-700/50 overflow-hidden"
          >
            <div className="flex flex-col h-full">
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.3 }}
                className="flex justify-end p-4"
              >
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={toggleSidebar}
                  className="text-slate-400 hover:text-white transition-colors duration-200"
                >
                  <XIcon className="h-6 w-6" />
                </motion.button>
              </motion.div>
              <nav className="flex-1 px-4 py-6 space-y-4 overflow-y-auto">
                {navigation.map((item, index) => (
                  <motion.div
                    key={item.name}
                    custom={index}
                    variants={itemVariants}
                  >
                    <Link
                      to={item.href}
                      className="block"
                      onClick={toggleSidebar}
                    >
                      <motion.div
                        whileHover={{ scale: 1.05, x: 5 }}
                        whileTap={{ scale: 0.95 }}
                        className={`flex items-center p-3 rounded-lg transition-all duration-200 ${
                          location.pathname === item.href
                            ? "bg-slate-700/50 text-white"
                            : "text-slate-300 hover:bg-slate-700/30 hover:text-white"
                        }`}
                      >
                        <item.icon
                          className="h-6 w-6 mr-3"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium">{item.name}</span>
                      </motion.div>
                    </Link>
                  </motion.div>
                ))}
              </nav>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;