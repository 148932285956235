import React from 'react'

const LoadingComponent = () => {
  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-80">
      <div
        className="inline-block h-12 w-12 animate-spin rounded-full border-[3px] border-current border-t-transparent text-orange-600"
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default LoadingComponent
