export const models = [
  {
    name: "GPT-3.5",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png"
  },
  {
    name: "GPT-4",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png"
  },
  {
    name: "GPT-4O",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png"
  },
  {
    name: "GPT-4O-mini",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/codeai-gifs/openAI.png"
  },
  // Anthropic models with correct Claude app icon
  {
    name: "Claude-3-5-sonnet",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png"
  },
  {
    name: "Claude-3-opus",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png"
  },
  {
    name: "Claude-3-sonnet",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png"
  },
  {
    name: "Claude-3-haiku",
    logo: "https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/claude_app_icon.png"
  }
];
