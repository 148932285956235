import React from "react";
import {
  ClipboardIcon,
  CodeIcon,
  ChatIcon,
  InformationCircleIcon,
  DatabaseIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";

export const prompts = [
  {
    title: "Build an AI Chatbot",
    icon: <InformationCircleIcon className="h-8 w-8 text-blue-500" />,
    action: "buildAIChatbot",
    description:
      "Create a functional AI chatbot using Python and a simple NLP library.",
    prompt:
      "Can you guide me through building a functional AI chatbot using Python and a simple NLP library?",
  },
  {
    title: "Develop a Smart Contract",
    icon: <CodeIcon className="h-8 w-8 text-green-500" />,
    action: "developSmartContract",
    description:
      "Write and deploy a smart contract on the Ethereum blockchain using Solidity.",
    prompt:
      "Can you help me write and deploy a smart contract on the Ethereum blockchain using Solidity?",
  },
  {
    title: "Set Up a Blockchain Network",
    icon: <DatabaseIcon className="h-8 w-8 text-purple-500" />,
    action: "setUpBlockchainNetwork",
    description: "Launch a private blockchain network and add nodes to it.",
    prompt:
      "Can you provide steps to launch a private blockchain network and add nodes to it?",
  },
  {
    title: "Implement CI/CD Pipeline",
    icon: <ClipboardIcon className="h-8 w-8 text-yellow-500" />,
    action: "implementCICD",
    description:
      "Set up a continuous integration and deployment (CI/CD) pipeline for a project.",
    prompt:
      "Can you guide me through setting up a continuous integration and deployment (CI/CD) pipeline for a project?",
  },
  {
    title: "Secure a Web Application",
    icon: <ShieldCheckIcon className="h-8 w-8 text-red-500" />,
    action: "secureWebApp",
    description:
      "Apply best practices to secure a web application from common threats.",
    prompt:
      "Can you provide best practices to secure a web application from common threats?",
  },
  {
    title: "Conduct a Code Review",
    icon: <ChatIcon className="h-8 w-8 text-teal-500" />,
    action: "conductCodeReview",
    description:
      "Review code for optimization, security, and adherence to best practices.",
    prompt:
      "Can you explain how to conduct a code review for optimization, security, and adherence to best practices?",
  },
];

export const PromptCards = ({ onCardClick }) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-4">
      {prompts.map((prompt, index) => (
        <div
          key={index}
          onClick={() => onCardClick(prompt.prompt)}
          className="flex flex-col items-center justify-center p-4 bg-gray-800 rounded-lg shadow-md cursor-pointer hover:shadow-xl transform transition duration-300 ease-in-out hover:scale-105 active:scale-95 w-48"
        >
          {prompt.icon}
          <span className="mt-4 text-base font-semibold text-white text-center">
            {prompt.title}
          </span>
          <p className="mt-2 text-xs text-gray-300 text-center">
            {prompt.description}
          </p>
        </div>
      ))}
    </div>
  );
  
  
  
};
