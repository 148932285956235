import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/authProvider";
import axios from "axios";
import { getUser, getSettings } from "../utils/api";
import { AnimatePresence, motion } from "framer-motion";

function EmptyState() {
  const { sessionId, hasSubscription } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const {
    setToken,
    setUser,
    setSettings,
    switchModel,
    authenticate,
    setHasSubscription,
    logout,
    token,
  } = useAuth();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsAnimating(true);

    try {
      const response = await axios.post(
        "https://api.codeai.studio/api/auth/login",
        {
          email,
          password,
        }
      );
      const token = response.data.jwt;
      localStorage.setItem("token", token);
      setToken(token);
      authenticate(token);
      setIsTokenSet(true);
    } catch (error) {
      setError(
        "Failed to sign in. Please check your credentials and try again."
      );
      setIsAnimating(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.codeai.studio/api/auth/signup",
        {
          email,
          password,
        }
      );
      const token = response.data.jwt;
      localStorage.setItem("token", token);
      setToken(token);
      authenticate(token);
      setIsTokenSet(true);
    } catch (error) {
      setError(
        "Failed to sign up. Please check your information and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userData = await getUser(token);
        const settingsData = await getSettings(token);
        setSettings(settingsData);
        setUser(userData.user);
        switchModel(settingsData.selectedModel);
      } catch (error) {
        if (
          error.response &&
          error.response.data === "Active subscription not found for this user."
        ) {
          setHasSubscription(false);
        } else {
          console.error("Failed to fetch user:", error);
          logout();
        }
      } finally {
        setLoading(false);
      }
    };

    if (isTokenSet) {
      fetchData();
    }
  }, [
    isTokenSet,
    token,
    setSettings,
    setUser,
    switchModel,
    setHasSubscription,
    logout,
  ]);

  const handleGoogleSignIn = () => {
    const url = `https://api.codeai.studio/api/auth/google?sessionId=${sessionId}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 overflow-y-auto">
      <div className="container mx-auto py-1 px-4 sm:px-6 lg:px-8">
        <AnimatePresence>
          {!isAnimating ? (
            <motion.div
              key="form"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="w-full max-w-6xl mx-auto my-8 bg-black bg-opacity-30 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
            >
              <div className="flex flex-col lg:flex-row w-full">
                <div className="w-full lg:w-1/2 p-8 lg:p-12">
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    className="mb-8"
                  >
                    <h1 className="font-bold text-4xl font-spac3 text-white mb-2">
                      CodeAI
                    </h1>
                    <p className="text-gray-300">
                      Your AI-powered coding assistant
                    </p>
                  </motion.div>
                  <form
                    onSubmit={isSignUp ? handleSignUp : handleSignIn}
                    className="space-y-6"
                  >
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3, duration: 0.5 }}
                    >
                      <label
                        className="block text-gray-300 text-sm font-semibold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="bg-white bg-opacity-10 text-white rounded-lg w-full p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="your@email.com"
                        required
                      />
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4, duration: 0.5 }}
                    >
                      <label
                        className="block text-gray-300 text-sm font-semibold mb-2"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        className="bg-white bg-opacity-10 text-white rounded-lg w-full p-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="••••••••"
                        required
                      />
                    </motion.div>
                    {!isSignUp && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                        className="flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="remember"
                            className="mr-2"
                          />
                          <label
                            htmlFor="remember"
                            className="text-gray-300 text-sm"
                          >
                            Remember me
                          </label>
                        </div>
                        <a
                          href="#"
                          className="text-blue-400 text-sm hover:underline"
                        >
                          Forgot Password?
                        </a>
                      </motion.div>
                    )}
                    <motion.button
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6, duration: 0.5 }}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg py-4 font-bold hover:from-blue-600 hover:to-purple-700 transition duration-300"
                      type="submit"
                    >
                      {isSignUp ? "Sign Up" : "Sign In"}
                    </motion.button>
                  </form>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    className="mt-8"
                  >
                    <div className="flex items-center justify-center">
                      <div className="border-t border-gray-600 w-full"></div>
                      <span className="text-gray-400 px-3 bg-transparent">
                        or
                      </span>
                      <div className="border-t border-gray-600 w-full"></div>
                    </div>
                    <button
                      onClick={handleGoogleSignIn}
                      className="w-full mt-4 bg-white text-gray-800 rounded-lg py-4 font-bold flex items-center justify-center hover:bg-gray-100 transition duration-300"
                    >
                      <svg
                        className="w-6 h-6 mr-2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
                          fill="#4285F4"
                        />
                      </svg>
                      Continue with Google
                    </button>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8, duration: 0.5 }}
                    className="mt-6 text-center"
                  >
                    <p className="text-gray-300">
                      {isSignUp
                        ? "Already have an account?"
                        : "Don't have an account?"}
                      <button
                        onClick={() => setIsSignUp(!isSignUp)}
                        className="text-blue-400 ml-2 hover:underline"
                      >
                        {isSignUp ? "Sign In" : "Sign Up"}
                      </button>
                    </p>
                  </motion.div>
                </div>
                <div className="w-full lg:w-1/2 relative lg:block">
                  <motion.img
                    initial={{ scale: 1.2 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7 }}
                    className="object-cover w-full h-full"
                    src="https://ascend-spaces.ams3.cdn.digitaloceanspaces.com/login.jpg"
                    alt="CodeAI"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full px-8">
                    <h2 className="text-white text-4xl font-bold mb-4">
                      {isSignUp ? "Join CodeAI Today" : "Welcome Back"}
                    </h2>
                    <p className="text-gray-300 text-xl">
                      Enhance your development workflow with AI
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="loading"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.5 }}
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            >
              <div className="text-white text-center">
                <h2 className="text-3xl font-bold mb-4">Signing In...</h2>
                <p>Please wait while we authenticate you.</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default EmptyState;
